import axios from "axios";

import { getAuthToken } from "../utils/storage.utils";
import { BASE_API_URL } from "../constants/common.constant";
import i18next from "i18next";

export const REQUEST_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

const defaultOptions = {
  baseUrl: BASE_API_URL,
  headers: () => ({
    "Accept-Language": i18next.language,
    Authorization: getAuthToken() ? `Bearer ${getAuthToken()}` : undefined,
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    debug: true,
  }),
  error: {
    meta: {
      code: 503,
      status: "error",
      message:
        "Something went wrong. Please check your internet connection or contact our support.",
    },
    data: null,
  },
};

const isFileExists = (inputValues = {}) => {
  const isContainsFile = (value) => {
    const isArray = Array.isArray(value);
    const isFile = value instanceof File;
    const isObject =
      typeof value === "object" && !isArray && value !== null && !isFile;

    if (isFile) return true;
    if (isArray || isObject) {
      if (isObject) value = Object.values(value);
      for (let index in value) {
        if (isContainsFile(value?.[index])) return true;
      }
    }

    return false;
  };

  const values = Object.values(inputValues);
  for (let index in values) {
    if (isContainsFile(values?.[index])) return true;
  }

  return false;
};

const getContentType = (request) => ({
  "Content-Type": isFileExists(request)
    ? "multipart/form-data"
    : "application/json",
});

const httpRequest = ({ url, method, headers, request, responseType }) => {
  const startTime = performance.now();

  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      headers: headers ?? {
        ...defaultOptions.headers(),
        ...getContentType(request),
      },
      params: method === REQUEST_METHODS.GET ? request : undefined,
      data: method !== REQUEST_METHODS.GET ? request : undefined,
      responseType: responseType ?? "json",
    }).then(
      (response) => {
        if (process.env.NODE_ENV !== "production") {
          console.log(
            url,
            `Execution time: ${performance.now() - startTime} milliseconds`
          );
        }
        resolve(response);
      },
      (error) => {
        if (process.env.NODE_ENV !== "production") {
          console.log(
            url,
            `Execution time: ${performance.now() - startTime} milliseconds`
          );
        }
        reject(
          error?.response?.data instanceof Blob
            ? { meta: { message: error?.response?.statusText } }
            : error?.response?.data ?? defaultOptions.error
        );
        // console.log(error?.response?.data ?? defaultOptions.error);
      }
    );
  });
};

const externalHttpRequest = (method, url, request) =>
  httpRequest({ url, method, request });

const internalHttpRequest = (method, url, request) =>
  httpRequest({
    url: `${defaultOptions.baseUrl}${url}`,
    method,
    request,
  });

const internalBlobHttpRequest = (method, url, request) =>
  httpRequest({
    url: `${defaultOptions.baseUrl}${url}`,
    method,
    headers: defaultOptions.headers(),
    request,
    responseType: "blob",
  });

const apiService = {
  get: (...args) => internalHttpRequest(REQUEST_METHODS.GET, ...args),
  post: (...args) => internalHttpRequest(REQUEST_METHODS.POST, ...args),
  put: (...args) => internalHttpRequest(REQUEST_METHODS.PUT, ...args),
  patch: (...args) => internalHttpRequest(REQUEST_METHODS.PATCH, ...args),
  delete: (...args) => internalHttpRequest(REQUEST_METHODS.DELETE, ...args),
  blobGet: (...args) => internalBlobHttpRequest(REQUEST_METHODS.GET, ...args),
  externalGet: (...args) => externalHttpRequest(REQUEST_METHODS.GET, ...args),
  externalPost: (...args) => externalHttpRequest(REQUEST_METHODS.POST, ...args),
  externalPut: (...args) => externalHttpRequest(REQUEST_METHODS.PUT, ...args),
  externalPatch: (...args) =>
    externalHttpRequest(REQUEST_METHODS.PATCH, ...args),
  externalDelete: (...args) =>
    externalHttpRequest(REQUEST_METHODS.DELETE, ...args),
};

export default apiService;
